import * as CONS from '~/constants';

// additionalHeadにて上書き・追加可能なhead
const overridableHead = ['title', 'titleTemplate', 'meta', 'link', 'script', 'style', 'noscript'];
// arrayは追加のみ
const arrayKeys = ['meta', 'link', 'script', 'style', 'noscript'];

const PageMixin = {
  head () {
    const url = `${process.env.ORIGIN}${this.$router.currentRoute.path}/`
      // 末尾スラッシュ2個以上の場合、1つにする
      .replace(/\/\/+$/, '/');
    const result = {
      title: this.$options.title,
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.$options.title,
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: url,
        },
      ],
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: url,
        },
      ],
    };

    if (this.$options.titleTemplate) {
      result.titleTemplate = this.$options.titleTemplate;
    }

    if (this.$options.additionalHead) {
      this._mergeHead(result);
    }

    return result;
  },

  computed: {
    CONS () {
      return CONS;
    },
  },

  methods: {
    _mergeHead (current) {
      let additionalHead = this.$options.additionalHead;
      if (typeof this.$options.additionalHead === 'function') {
        additionalHead = this.$options.additionalHead.apply(this);
      }
      if (typeof additionalHead !== 'object') {
        throw new TypeError('additionalHead must be object or function');
      }

      for (const key of overridableHead) {
        const value = additionalHead[key];

        if (arrayKeys.includes(key)) {
          if (!value) {
            continue;
          }
          if (!Array.isArray(value)) {
            throw new TypeError(`additionalHead.${key} must be array`);
          }
          if (!current[key]) {
            current[key] = [];
          }
          current[key].push(...value);
          continue;
        }

        if (value || value === '') {
          current[key] = value;
        }
      }
    },
  },
};

export default PageMixin;
