import * as CONS from '@/constants';

export default {
  name: 'top-page-header',
  components: {},
  props: {
    isLocalPlace: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      CONS,
      isTelRestricted: false,
    };
  },
  mounted () {
    this.isTelRestricted = this.$getIsTelRestricted();
  },
  computed: {
    formLink () {
      return this.isLocalPlace ? '/localPlace/form/' : '/form/';
    },
    phoneNumber () {
      return this.isLocalPlace ? CONS._PHONE_NUMBER_LOCALPLACE : CONS._PHONE_NUMBER;
    },
    telLink () {
      return `tel:${this.phoneNumber}`;
    },
  },
  methods: {},
};
