/**
 * @typedef Options
 * @property {boolean} [withContext] - '@context' を追加するか
 * @property {string} [path] - urlとどちらかのみ指定
 * @property {string} [url] - pathとどちらかのみ指定
 * @property {string} [imagePath] - imageUrlとどちらかのみ指定
 * @property {string} [imageUrl] - imagePathとどちらかのみ指定
 */

import { _DESCRIPTION_GLOBAL } from '@/constants';

/**
 * URLを生成
 * @param {string} path
 * @return {string}
 */
export function getUrl (path) {
  return `${process.env.ORIGIN}${path}`;
}

/** @typedef {Object} ListItem */

/**
 * パンくずリスト用 ListItem を生成
 * @param {number} position
 * @param {string} name
 * @param {string} path
 * @param {Options} [options]
 * @return {{ '@type': string, position: number, name: string, item: string }}
 */
export function createListItem (position, name, path, options) {
  return _withOptions({
    '@type': 'ListItem',
    position,
    name,
    item: getUrl(path),
  }, options);
}

/** @typedef {Object} BreadCrumbList */

/**
 * パンくずリストを生成
 * @param {Array<{ name: string, path: string }>} items
 * @param {Options} [options]
 * @return {BreadCrumbList}
 */
export function createBreadCrumbList (items, options) {
  return _withOptions({
    '@type': 'BreadcrumbList',
    itemListElement: items.map((item, index) => createListItem(index + 1, item.name, item.path)),
  }, options);
}

/** @typedef {Object} MedicalCondition */

/**
 * MedicalConditionを生成
 * @param {string|string[]} name
 * @param {Options} [options]
 * @return {MedicalCondition}
 */
export function createMedicalCondition (name, options) {
  return _withOptions({
    '@type': 'MedicalCondition',
    name,
  }, options);
}

/** @typedef {Object} MedicalWebPage */

/**
 * MedicalWebPageを生成
 * @param {string} name
 * @param {string|null} [description]
 * @param {string|string[]|null} [conditionName]
 * @param {string|null} [audience='https://schema.org/Patient']
 * @param {Options} [options]
 * @return {MedicalWebPage}
 */
export function createMedicalWebPage (name, description, conditionName, audience, options) {
  return _withOptions({
    '@type': 'MedicalWebPage',
    about: createMedicalCondition(conditionName || ['内科', '小児科', '外科', '整形外科']),
    audience: audience || 'https://schema.org/Patient',
    name,
    description: description || _DESCRIPTION_GLOBAL,
  }, options);
}

/** @typedef {Object} MedicalOrganization */

/**
 * MedicalOrganizationを生成
 * @param {string} name
 * @param {string} [telephone]
 * @param {string|{ prefecture: string, city: string, streetAddress: string }} address
 * @param {string} [sameAs]
 * @param {Options} [options]
 * @return {MedicalOrganization}
 */
export function createMedicalOrganization (name, telephone, address, sameAs, options) {
  return _withOptions({
    '@type': 'MedicalOrganization',
    name,
    telephone,
    address: typeof address === 'string' ? address : createPostalAddress(address.prefecture, address.city, address.streetAddress),
    sameAs: sameAs || undefined,
  }, options);
}

/**
 * hospitalからMedicalOrganizationを生成 (@context 有り)
 * @param {{ name: string, tel: string, address: string, site_url?: string }} hospital
 * @return {MedicalOrganization}
 */
export function hospitalToMedicalOrganization (hospital) {
  return createMedicalOrganization(
    hospital.name,
    hospital.tel,
    hospital.address,
    hospital.site_url || undefined,
    { withContext: true },
  );
}

/**
 * PostalAddressを生成
 * @typedef {Object} PostalAddress
 * @param {string} prefecture
 * @param {string} city
 * @param {string} streetAddress
 * @param {Options} [options]
 * @return {PostalAddress}
 */
export function createPostalAddress (prefecture, city, streetAddress, options) {
  return _withOptions({
    '@type': 'PostalAddress',
    addressRegion: prefecture,
    addressLocality: city,
    streetAddress,
  }, options);
}

/**
 * オプションの処理
 * @template T
 * @param {T} obj
 * @param {Options} [options={}]
 * @return {T}
 */
function _withOptions (obj, options = {}) {
  const result = { ...obj };
  if (options.withContext) {
    result['@context'] = 'https://schema.org';
  }
  if (options.url) {
    result.url = options.url;
  } else if (options.path) {
    result.url = getUrl(options.path);
  }
  if (options.imageUrl) {
    result.image = options.imageUrl;
  } else if (options.imagePath) {
    result.image = getUrl(options.imagePath);
  }
  return result;
}
