import { render, staticRenderFns } from "./index.vue?vue&type=template&id=527dfcfa&scoped=true&"
import script from "./script.js?vue&type=script&lang=js&"
export * from "./script.js?vue&type=script&lang=js&"
import style0 from "./mainNav.scss?vue&type=style&index=0&id=527dfcfa&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "527dfcfa",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PhoneCallButton: require('/Users/mba/ghq/github.com/calldoctor119/calldoctorHP-renewal/components/small-components/phoneCallButton/index.vue').default,AppStoreButton: require('/Users/mba/ghq/github.com/calldoctor119/calldoctorHP-renewal/components/small-components/appStoreButton/index.vue').default})
