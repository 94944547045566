import * as CONS from '@/constants';

export default {
  name: 'phone-call-button',
  components: {},
  props: {
    isLocalPlace: {
      type: Boolean,
      default: false,
    },
    areaRestricted: {
      type: Boolean,
      default: null,
    },
    reverseColor: {
      type: Boolean,
      default: false,
    },
    shortText: {
      type: Boolean,
      default: false,
    },
    useAcceptable: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      CONS,
      isGlobalRestricted: false,
      isAcceptable: false,
      isMounted: false,
    };
  },
  async mounted () {
    this.isGlobalRestricted = this.$getIsTelRestricted();
    this.isAcceptable = await this.$isAcceptable();
    this.isMounted = true;
  },
  computed: {
    disabled () {
      if (this.useAcceptable) {
        return !this.isAcceptable || this.areaRestricted || this.isGlobalRestricted;
      }
      return this.areaRestricted || this.isGlobalRestricted;
    },
    phoneNumber () {
      return this.isLocalPlace ? CONS._PHONE_NUMBER_LOCALPLACE : CONS._PHONE_NUMBER;
    },
    telLink () {
      return `tel:${this.phoneNumber}`;
    },
    iconPath () {
      if (this.disabled) {
        if (this.shortText) {
          return null;
        }

        return require('@/assets/icons/phone_white_outline.svg');
      }

      if (this.reverseColor) {
        return require('@/assets/icons/phone_white_outline.svg');
      }

      return require('@/assets/icons/phone_blue_outline.svg');
    },
    text () {
      if (this.isGlobalRestricted) {
        if (this.shortText) {
          return '電話の受付停止中';
        }
        return '只今、電話での受付を停止しております';
      }

      if (this.areaRestricted) {
        if (this.shortText) {
          return '電話の受付停止中';
        }
        return '只今、電話での受付を停止しております';
      }

      if (this.useAcceptable && !this.isAcceptable) {
        if (this.shortText) {
          return '電話の受付時間外';
        }
        return '只今、電話での受付を停止しております';
      }

      return this.phoneNumber;
    },
  },
  methods: {},
};
