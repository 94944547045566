import * as CONS from '~/constants';

export default {
  name: 'main-nav',
  components: {},
  props: {
    isLocalPlace: {
      type: Boolean,
      default: false,
    },
    isDkSelect: {
      type: Boolean,
      default: false,
    },
    hideMobileBubble: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    return {
      CONS,
      isHiddenMenu: true,
      isLoading: true,
      isAcceptable: false,
      hideSPHamburger: false,
      hideSPReserveBtn: false,
      telNumber: '',
      telLink: '',
      topLink: '/',
      formLink: '/form/',
      isTelRestricted: false,
    };
  },
  computed: {
  },
  created () {
    if (this.isLocalPlace) {
      this.telNumber = CONS._PHONE_NUMBER_LOCALPLACE;
      this.hideSPHamburger = true;
      this.topLink = '/localPlace/';
      this.formLink = '/localPlace/form/';
    } else if (this.isDkSelect) {
      this.telNumber = CONS._PHONE_NUMBER_DSELECT;
      this.hideSPHamburger = true;
      this.topLink = '/dkselect';
      this.formLink = '/dkselect/form/';
    } else {
      this.telNumber = CONS._PHONE_NUMBER;
    }
    this.telLink = 'tel:' + this.telNumber;
  },
  async mounted () {
    // 電話受付API
    // 値がストックされる
    await this.$checkIsTelRestricted();
    this.isTelRestricted = this.$getIsTelRestricted();

    // SEOページではボタンを隠す
    if (location.pathname.includes('/hospitals')) {
      this.hideSPReserveBtn = true;
    }

    if (location.pathname.includes('/form')) {
      // form画面では必要ないので隠す
      this.isAcceptable = false;
      this.isLoading = false;
      return;
    }
    this.isLoading = true;
    this.isAcceptable = await this.$isAcceptable();
    this.isLoading = false;
  },
  methods: {},
};
