import * as CONS from '@/constants';

export default {
  name: 'patients-voice',
  components: {},
  props: [],
  data () {
    return {
      CONS,
      voices: [
        {
          photo: 'voice_1.png',
          width: 140,
          height: 140,
          name: 'S.S',
          message: '受付の方も先生もとても親切に対応してくださいました。「受診可能な病院がない」と保健所から言われ、とても不安な気持ちの中、来ていただけると分かった時の安心感は忘れません。PCR検査も対応可能で有り難かったです。',
        },
        {
          photo: 'voice_2.png',
          width: 140,
          height: 140,
          name: 'S.N',
          message: '優しい雰囲気の先生で、穏やかにお話ししてくださいました。息子もドキドキして先生の到着を待っていましたが、診察後、布団に入ると「優しい先生だった」ととても嬉しそうでした。',
        },
        {
          photo: 'voice_3.png',
          width: 140,
          height: 140,
          name: 'A.H',
          message: '発熱があったのでコロナの疑いがありそうな患者の診察に来て頂けるだけでも有難いのに、しかも深夜だったので本当に感謝の気持ちでいっぱいです。ありがとうございました。',
        },
      ],
    };
  },
  computed: {},
  methods: {},
};
