import * as CONS from '@/constants';

export default {
  name: 'media-exposure',
  components: {},
  props: [],
  data () {
    return {
      CONS,
    };
  },
  computed: {},
  methods: {},
};
