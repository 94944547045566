import { render, staticRenderFns } from "./index.vue?vue&type=template&id=7e43d340&scoped=true&"
import script from "./script.js?vue&type=script&lang=js&"
export * from "./script.js?vue&type=script&lang=js&"
import style0 from "./index.scss?vue&type=style&index=0&id=7e43d340&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e43d340",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ConsultationBox: require('/Users/mba/ghq/github.com/calldoctor119/calldoctorHP-renewal/components/footer-boxes/consultationBox/index.vue').default,FooterBox: require('/Users/mba/ghq/github.com/calldoctor119/calldoctorHP-renewal/components/footer-boxes/footerBox/index.vue').default})
