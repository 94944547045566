import * as CONS from '~/constants';

export default {
  name: 'footer-box',
  components: {},
  props: {
    isLocalPlace: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      CONS,
    };
  },
  computed: {
    topLink () {
      return this.isLocalPlace ? '/localPlace/' : '/';
    },
  },
  methods: {},
};
