//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageMixin from '@/mixins/PageMixin';
import { _TITLE_GLOBAL } from '@/constants';
import { createMedicalWebPage } from '@/util/structuredData';

export default {
  mixins: [PageMixin],
  title: _TITLE_GLOBAL,
  titleTemplate: '%s',
  additionalHead () {
    return {
      script: [
        {
          hid: 'structured-data',
          type: 'application/ld+json',
          json: [
            createMedicalWebPage(
              _TITLE_GLOBAL,
              null,
              null,
              null,
              { withContext: true, path: '/' },
            ),
          ],
        },
      ],
    };
  },
};
