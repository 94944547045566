import * as CONS from '@/constants';

export default {
  name: 'cooperated-local-government',
  components: {},
  props: [],
  data () {
    return {
      CONS,
      localGovernments: [
        {
          flag: 'flag_saitama_pref.svg',
          name: '埼玉県',
        },
        {
          flag: 'flag_hiroshima_pref.svg',
          name: '広島県',
        },
        {
          flag: 'flag_yamanashi_pref.svg',
          name: '山梨県',
        },
      ],
    };
  },
  computed: {},
  methods: {},
};
