import * as CONS from '@/constants';

export default {
  name: 'label-text',
  components: {},
  props: {
    outline: {
      type: Boolean,
      required: false,
    },
    blue: {
      type: Boolean,
      required: false,
    },
    pink: {
      type: Boolean,
      required: false,
    },
    grey: {
      type: Boolean,
      required: false,
    },
  },
  data () {
    return {
      CONS,
    };
  },
  computed: {},
  methods: {},
};
