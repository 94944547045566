import { render, staticRenderFns } from "./index.vue?vue&type=template&id=49aac389&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MainNav: require('/Users/mba/ghq/github.com/calldoctor119/calldoctorHP-renewal/components/mainNav/index.vue').default,TopPageHeader: require('/Users/mba/ghq/github.com/calldoctor119/calldoctorHP-renewal/components/top-boxes/topPageHeader/index.vue').default,AboutService: require('/Users/mba/ghq/github.com/calldoctor119/calldoctorHP-renewal/components/top-boxes/aboutService/index.vue').default,FreeForChildren: require('/Users/mba/ghq/github.com/calldoctor119/calldoctorHP-renewal/components/top-boxes/freeForChildren/index.vue').default,HowToUse: require('/Users/mba/ghq/github.com/calldoctor119/calldoctorHP-renewal/components/top-boxes/howToUse/index.vue').default,JapanMap: require('/Users/mba/ghq/github.com/calldoctor119/calldoctorHP-renewal/components/top-boxes/japanMap/index.vue').default,AvailableExam: require('/Users/mba/ghq/github.com/calldoctor119/calldoctorHP-renewal/components/top-boxes/availableExam/index.vue').default,DoctorMemberList: require('/Users/mba/ghq/github.com/calldoctor119/calldoctorHP-renewal/components/top-boxes/doctorMemberList/index.vue').default,CooperatedLocalGovernment: require('/Users/mba/ghq/github.com/calldoctor119/calldoctorHP-renewal/components/top-boxes/cooperatedLocalGovernment/index.vue').default,MediaExposure: require('/Users/mba/ghq/github.com/calldoctor119/calldoctorHP-renewal/components/top-boxes/mediaExposure/index.vue').default,PatientsVoice: require('/Users/mba/ghq/github.com/calldoctor119/calldoctorHP-renewal/components/top-boxes/patientsVoice/index.vue').default,ContactBox: require('/Users/mba/ghq/github.com/calldoctor119/calldoctorHP-renewal/components/top-boxes/contactBox/index.vue').default,CooperatedService: require('/Users/mba/ghq/github.com/calldoctor119/calldoctorHP-renewal/components/top-boxes/cooperatedService/index.vue').default,FooterContainer: require('/Users/mba/ghq/github.com/calldoctor119/calldoctorHP-renewal/components/footerContainer/index.vue').default})
