import * as CONS from '@/constants';
export default {
  name: 'available-exam',
  components: {},
  props: [],
  data () {
    return {
      CONS,
    };
  },
  computed: {},
  methods: {},
};
